/** @jsx jsx */

import { jsx, Text, Image, Link } from "theme-ui";
import { Stack, StackItem } from "office-ui-fabric-react";
import Barcode from "react-barcode";
import { doFormatTitle } from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import React from "react";
import PropTypes from "prop-types";
import {
  abhyasStage,
  NEWPRACTITIONER,
  PRACTITIONER,
  SEEKER,
  TRAINER,
} from "sites-common/utils/validations";
import Logo from "../imgs/logo.png";
import ProfileImage from "../imgs/meditator.jpg";

const NA = "--Not Available--";

function IDCard({ srcmProfile, showContacts, onlyContacts }) {
  const item = { ...srcmProfile };
  if (srcmProfile?.photoUrl) {
    item.photo_url = srcmProfile?.photoUrl;
  }
  if (srcmProfile?.abhyasiId) {
    item.ref = srcmProfile?.abhyasiId;
  }
  const aStage = abhyasStage(item?.record_type, item?.ref);
  if (!aStage) return null;

  return (
    <React.Fragment>
      {!onlyContacts && (
        <div
          sx={{
            boxShadow: "0px 3px 6px #00000029",

            margin: "auto",
            borderRadius: "10px",
            width: "280px",
            minHeight: "400px",
            bg: "white",
          }}
        >
          <Stack horizontalAlign="center">
            <StackItem>
              <div
                sx={{
                  border: "solid 2px #D4C2AA",

                  bg: "#E4D2BA",
                  height: "5px",
                  borderRadius: "30px",
                  width: "40px",
                  mx: "auto",
                  mt: 3,
                }}
              />

              <Image alt="-" sx={{ width: "175px", mx: 3, mt: 3 }} src={Logo} />
            </StackItem>

            <StackItem>
              <Text sx={{ fontSize: "1.2em" }}>
                {doFormatTitle(item?.name || "")}{" "}
              </Text>
            </StackItem>
            <StackItem>
              <Image
                sx={{
                  mt: 1,
                  lineHeight: "160px",
                  height: "160px",
                  textAlign: "center",
                  border: "solid 1px grey",
                }}
                src={item?.photo_url ? item?.photo_url : ProfileImage}
                alt="Photo Missing"
              />
            </StackItem>
            <StackItem>
              {aStage !== SEEKER && (
                <Barcode value={item?.ref} height={50} width={1.5} />
              )}
            </StackItem>
            <StackItem
              sx={{ m: 1, color: item?.gender === "M" ? "darkblue" : "purple" }}
            >
              {aStage === TRAINER && (
                <Text sx={{ color: "red" }}>Preceptor</Text>
              )}
              {aStage === PRACTITIONER && "Practitioner"}
              {aStage === NEWPRACTITIONER && "New Practitioner"}
              {aStage === SEEKER && (
                <div sx={{ textAlign: "center" }}>
                  <div
                    sx={{
                      color: "darkgreen",
                      fontFamily: "Lucida Console",
                      fontSize: "1.5em",
                    }}
                  >
                    SEEKER
                  </div>
                  <div sx={{ mx: 3 }}>
                    {" "}
                    sign in email and mobile are not registered with any ID;
                    either a <b>new seeker</b> or a <b>duplicate account</b>{" "}
                  </div>
                </div>
              )}
            </StackItem>
          </Stack>
        </div>
      )}

      {(showContacts || onlyContacts) && (
        <div
          sx={{
            boxShadow: "0px 3px 6px #00000029",

            mx: "auto",
            my: 2,
            borderRadius: "10px",
            width: "280px",
            p: 3,
            bg: "white",
          }}
        >
          <div sx={{ fontStyle: "italic", fontWeight: 300, fontSize: "0.9em" }}>
            Login Email:{" "}
          </div>
          <Link href={`mailto:${item?.email}`}>{item?.email || NA}</Link>
          <div
            sx={{
              mt: 1,
              fontStyle: "italic",
              fontWeight: 300,
              fontSize: "0.9em",
            }}
          >
            Login Mobile:{" "}
          </div>

          <div>{item?.mobile || NA}</div>
          {item?.phone && (
            <div>
              <div
                sx={{
                  mt: 1,
                  fontStyle: "italic",
                  fontWeight: 300,
                  fontSize: "0.9em",
                }}
              >
                Other Phone:{" "}
              </div>

              <div>{item?.phone || NA}</div>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
}

IDCard.propTypes = {
  srcmProfile: PropTypes.shape({
    name: PropTypes.string,
    photoUrl: PropTypes.string,
    photo_url: PropTypes.string,
    abhyasiId: PropTypes.string,
    ref: PropTypes.string,
    gender: PropTypes.string,
    record_type: PropTypes.string,
    email: PropTypes.string,
    mobile: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
  showContacts: PropTypes.bool,
  onlyContacts: PropTypes.bool,
};
IDCard.defaultProps = {
  showContacts: false,
  onlyContacts: false,
};
export default IDCard;
